'use client'

import { Image } from 'cms-types'

import { PayloadImage } from '../PayloadImage'

type Props = {
  image: Image
  title?: string | null
  text: (JSX.Element | null)[]
}

export default function ImageText({ image, text, title }: Props) {
  return (
    <section className='mx-[15px] my-9 flex flex-col gap-y-8'>
      <PayloadImage src={image} sizes='100vw' className='aspect-[7/3] w-full' />

      {title && <h3 className='text-center font-bold'>{title}</h3>}

      {text.length > 0 && (
        <div className='block w-full grid-cols-6 lg:grid'>
          <div className='col-start-2 col-end-6'>{text}</div>
        </div>
      )}
    </section>
  )
}
