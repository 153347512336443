'use client'

import './TeaserSliderStyles.css'

import { LazyMotion, m } from 'framer-motion'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useId } from 'react-aria'
import Slider, { CustomArrowProps, Settings } from 'react-slick'

import Icon from '../../components/Icon'
import { useWindowSize } from '../../hooks/useWindowSize'
import { Media } from '../../types'
import TeaserSlide from './TeaserSlide'

const motionFeatures = () => import('ui/helpers/motionFeatures').then(res => res.default)

type TeaserSliderProps = {
  slides: {
    title: string
    description: string
    buttonText: string
    media?: Media
    mobileMedia?: Media
    url?: string
    isMediaFullWidth?: boolean
  }[]
}

export default function TeaserSlider({ slides }: TeaserSliderProps) {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [mounted, setMounted] = useState(false)

  const sliderId = useId()

  const sliderRef = useRef<Slider>(null)
  const { isDesktop } = useWindowSize()

  useEffect(() => {
    setMounted(true)
  }, [])

  const sliderSettings: Settings = useMemo(
    () => ({
      autoplay: false,
      swipeToSlide: true,
      autoplaySpeed: 5000,
      speed: 500,
      arrows: mounted && isDesktop,
      nextArrow: <NextSlideButton />,
      prevArrow: <PreviousSlideButton />,
      slidesToScroll: 1,
      slidesToShow: 1,
      beforeChange: (_, next) => {
        setCurrentSlide(next)
      },
      dots: true,
      // @ts-ignore
      appendDots: dots => <DotsContainer dots={dots} />,
      customPaging: index => (
        <Dot
          isActive={currentSlide === index}
          sliderId={sliderId}
          slideNumber={index + 1}
          onClick={() => {
            sliderRef.current?.slickGoTo(index)
          }}
        />
      ),
      infinite: true,
    }),
    [currentSlide, isDesktop, mounted, sliderId],
  )

  return (
    <LazyMotion features={motionFeatures} strict>
      <div className='mx-5 pt-4 xl:mx-10 xl:mb-10 xl:pt-5'>
        {/* @ts-ignore */}
        <Slider
          ref={sliderRef}
          {...sliderSettings}
          className='teaser-slider group relative isolate !flex flex-col gap-3 lg:gap-8'
        >
          {slides.map((props, index) => (
            <TeaserSlide key={index} {...props} />
          ))}
        </Slider>
      </div>
    </LazyMotion>
  )
}

const NextSlideButton = ({ onClick }: CustomArrowProps) => {
  return (
    <button
      aria-hidden
      onClick={onClick}
      tabIndex={-1}
      className='absolute right-0 top-1/2 z-10 -translate-y-1/2 p-5 opacity-0 transition-opacity duration-500 group-hover:opacity-100'
    >
      <Icon name='MdChevronRight' size={32} />
    </button>
  )
}

const PreviousSlideButton = ({ onClick }: CustomArrowProps) => {
  return (
    <button
      aria-hidden
      onClick={onClick}
      tabIndex={-1}
      className='absolute left-0 top-1/2 z-10 -translate-y-1/2 p-5 opacity-0 transition-opacity duration-500 group-hover:opacity-100'
    >
      <Icon name='MdChevronLeft' size={32} />
    </button>
  )
}

const DotsContainer = ({ dots }: { dots: React.ReactNode }) => {
  return (
    <div className='flex justify-center'>
      <ul className='grid w-[384px] auto-cols-auto grid-flow-col'>{dots}</ul>
    </div>
  )
}

const Dot = ({
  sliderId,
  isActive,
  onClick,
  slideNumber,
}: {
  sliderId: string
  isActive: boolean
  onClick: () => void
  slideNumber: number
}) => {
  return (
    <button
      onClick={onClick}
      aria-label={`Navigate to ${slideNumber} slide`}
      className='w-full py-2'
    >
      <div className='relative h-0.5 bg-ecrudark'>
        {isActive && (
          <m.div
            layoutId={`SliderDot${sliderId}`}
            className='absolute inset-0 z-10 h-0.5 bg-black'
          />
        )}
      </div>
    </button>
  )
}
