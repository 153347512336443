'use client'

import { useEffect, useMemo, useRef } from 'react'

import { useId } from '../hooks/useId'

type DynamicCodeProps = {
  readonly html?: string | null
  readonly css?: string | null
  readonly cssScopeToBlock: boolean
  readonly js?: string | null
}

export const DynamicCode = ({ html, css, js, cssScopeToBlock }: DynamicCodeProps) => {
  const { id, cssId, jsId } = useId()
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const currentRef = containerRef.current
    if (!currentRef || !js) return

    const cleanUpVar = `DYNAMIC_CODE_BLOCK_${jsId}_CLEANUP`
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.innerHTML = `
      window['${cleanUpVar}'] = (()=>{
        const CONTAINER_ID='${id}'; 
        ${js}
      })()
    `
    currentRef.appendChild(script)

    return () => {
      if (cleanUpVar in window) {
        //@ts-ignore
        const cleanup = window[cleanUpVar] as (() => void) | undefined

        if (cleanup && typeof cleanup === 'function') {
          cleanup()
        }

        //@ts-ignore
        delete window[cleanUpVar]
      }

      currentRef.removeChild(script)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const cssWithId = useMemo(
    () => (css ? css.replace(/_CONTAINER_ID_/g, cssId) : null),
    [css, cssId],
  )

  return (
    <div id={id} ref={containerRef}>
      {html && <div className='dynamic-code-html' dangerouslySetInnerHTML={{ __html: html }} />}
      {css && (
        <style type='text/css'>
          {cssScopeToBlock ? `@scope (#${cssId}) { ${cssWithId} }` : cssWithId}
        </style>
      )}
    </div>
  )
}
