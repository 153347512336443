'use client'

import './Carousel.css'

import { CarouselBlock, Image } from 'cms-types'
import { LazyMotion, m } from 'framer-motion'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useId } from 'react-aria'
import Slider, { CustomArrowProps, Settings } from 'react-slick'

import Icon from '../../components/Icon'
import LoadingText from '../../components/LoadingText'
import { useWindowSize } from '../../hooks/useWindowSize'
import { LoadableComponentProps } from '../../types'
import { CarouselSlide } from './CarouselSlide'

const motionFeatures = () => import('ui/helpers/motionFeatures').then(res => res.default)

export type CarouselProps = LoadableComponentProps<
  Pick<CarouselBlock, 'title' | 'slidesToShow' | 'accessibility'> & {
    slides: {
      id?: string | null
      label: string
      image?: Image
      url?: string | null
    }[]
  }
>

export default function Carousel({ loading, data }: CarouselProps) {
  return loading ? <MockLoadingCarousel /> : <RealCarousel {...data} />
}

const MockLoadingCarousel = () => {
  const { isDesktop, isTablet } = useWindowSize()

  return (
    <div className='mb-20 mt-10 xl:mb-36 xl:mt-20 xl:gap-8'>
      <div className='mx-auto flex justify-center px-5 text-center'>
        <h4 className='h4'>
          <LoadingText textLength={15} />
        </h4>
      </div>

      <div className='mx-auto mt-10'>
        <div className='relative isolate flex h-44 items-center justify-around gap-8 px-10 lg:h-56'>
          {new Array(isDesktop ? 3 : isTablet ? 2 : 1).fill(null).map((_, index) => (
            <CarouselSlide key={`loading-slide-${index}`} loading />
          ))}
        </div>
      </div>
    </div>
  )
}

const RealCarousel = ({
  accessibility,
  slidesToShow,
  slides,
  title,
}: NonNullable<CarouselProps['data']>) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [isSlider, setIsSlider] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const { isDesktop, isTablet } = useWindowSize()

  const sliderId = useId()
  const sliderRef = useRef<Slider>(null)

  useEffect(() => {
    setIsLoading(false)
  }, [])

  useEffect(() => {
    let slidesCount = slidesToShow.mobile

    if (isTablet) {
      slidesCount = slidesToShow.tablet
    }

    if (isDesktop) {
      slidesCount = slidesToShow.desktop
    }

    setIsSlider(slides.length > slidesCount)
  }, [isDesktop, isTablet, slides, slidesToShow])

  const sliderSettings: Settings = useMemo(
    () => ({
      autoplay: false,
      swipeToSlide: true,
      arrows: true,
      nextArrow: <NextSlideButton ariaLabel={accessibility.nextSlideButtonLabel} />,
      prevArrow: <PreviousSlideButton ariaLabel={accessibility.previousSlideButtonLabel} />,
      slidesToScroll: 1,
      slidesToShow: slidesToShow.desktop,
      centerMode: true,
      rows: 1,
      responsive: [
        {
          breakpoint: 639,
          settings: {
            slidesToShow: slidesToShow.mobile,
            arrows: false,
            customPaging: index => (
              <Dot
                isActive={currentSlide === index}
                sliderId={sliderId}
                onClick={() => {
                  sliderRef.current?.slickGoTo(index)
                }}
                slideNumber={index + 1}
              />
            ),
          },
        },
        {
          breakpoint: 767,
          settings: {
            variableWidth: true,
            slidesToShow: slidesToShow.mobile,
            arrows: false,
            customPaging: index => (
              <Dot
                isActive={currentSlide === index}
                sliderId={sliderId}
                onClick={() => {
                  sliderRef.current?.slickGoTo(index)
                }}
                slideNumber={index + 1}
              />
            ),
          },
        },
        {
          breakpoint: 1023,
          settings: {
            slidesToShow: slidesToShow.tablet,
            arrows: false,
            customPaging: index => (
              <Dot
                isActive={Math.floor(currentSlide / 2) === index}
                sliderId={sliderId}
                onClick={() => {
                  sliderRef.current?.slickGoTo(index)
                }}
                slideNumber={index + 1}
              />
            ),
          },
        },
        {
          breakpoint: 1279,
          settings: {
            slidesToShow: slidesToShow.tablet,
            customPaging: index => (
              <Dot
                isActive={Math.floor(currentSlide / 2) === index}
                sliderId={sliderId}
                onClick={() => {
                  sliderRef.current?.slickGoTo(index)
                }}
                slideNumber={index + 1}
              />
            ),
          },
        },
      ],
      beforeChange: (_, next) => {
        setCurrentSlide(next)
      },
      dots: true,
      // @ts-ignore
      appendDots: dots => <DotsContainer dots={dots} />,
      customPaging: index => (
        <Dot
          isActive={Math.floor(currentSlide / 3) === index}
          sliderId={sliderId}
          onClick={() => {
            sliderRef.current?.slickGoTo(index)
          }}
          slideNumber={index + 1}
        />
      ),
      infinite: true,
    }),
    [currentSlide, sliderId, slidesToShow, accessibility],
  )

  return (
    <LazyMotion features={motionFeatures} strict>
      <div className='mb-20 mt-10 xl:mb-36 xl:mt-20 xl:gap-8'>
        {title && (
          <div className='mx-auto flex justify-center px-5 text-center'>
            <h4 className='h4'>{title}</h4>
          </div>
        )}

        <div className='mx-auto mt-10'>
          {isSlider ? (
            /* @ts-ignore */
            <Slider
              ref={sliderRef}
              {...sliderSettings}
              className='slide-list relative isolate !flex h-44 items-center gap-8 lg:h-56 lg:px-5 xl:px-10'
            >
              {slides.map(slide => (
                <CarouselSlide key={slide.id} data={slide} />
              ))}
            </Slider>
          ) : (
            <div className='relative isolate flex h-44 items-center justify-around gap-8 px-10 lg:h-56'>
              {(isLoading ? slides.slice(0, Math.min(slides.length, 2)) : slides).map(slide => (
                <CarouselSlide key={slide.id} data={slide} />
              ))}
            </div>
          )}
        </div>
      </div>
    </LazyMotion>
  )
}

const NextSlideButton = ({ onClick, ariaLabel }: CustomArrowProps & { ariaLabel: string }) => {
  return (
    <button onClick={onClick} aria-label={ariaLabel} className='h-max p-5'>
      <Icon name='MdChevronRight' size={32} />
    </button>
  )
}

const PreviousSlideButton = ({ onClick, ariaLabel }: CustomArrowProps & { ariaLabel: string }) => {
  return (
    <button onClick={onClick} aria-label={ariaLabel} className='h-max p-5'>
      <Icon name='MdChevronLeft' size={32} />
    </button>
  )
}

const DotsContainer = ({ dots }: { dots: React.ReactNode }) => {
  return (
    <div className='absolute left-5 right-5 top-[calc(100%+20px)] flex justify-center xl:top-[calc(100%+32px)]'>
      <ul className='grid w-[384px] auto-cols-auto grid-flow-col'>{dots}</ul>
    </div>
  )
}

const Dot = ({
  sliderId,
  isActive,
  onClick,
  slideNumber,
}: {
  sliderId: string
  isActive: boolean
  onClick: () => void
  slideNumber: number
}) => {
  return (
    <button onClick={onClick} aria-label={`Go to ${slideNumber} slide`} className='w-full py-2'>
      <div className='relative h-0.5 bg-ecrudark'>
        {isActive && (
          <m.div
            layoutId={`SliderDot${sliderId}`}
            className='absolute inset-0 z-10 h-0.5 bg-black'
          />
        )}
      </div>
    </button>
  )
}
