import { Money } from './shared'
import { ProductVariant } from './product'

function getCurrencySymbol(currencyCode?: string): string | undefined {
  if (!currencyCode) return

  try {
    const formatter = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: currencyCode,
    })
    const parts = formatter.formatToParts()

    for (const part of parts) {
      if (part.type === 'currency') {
        return part.value
      }
    }

    return undefined
  } catch (error) {
    console.error('Error getting currency symbol:', error)
    return undefined
  }
}

export const printMoney = (money?: Money | { amount: string | number; currencyCode: string }) => {
  if (!money) return ''
  return `${Number(money.amount).toFixed(2).toString().replace('.', ',') || ''} ${
    getCurrencySymbol(money.currencyCode) ?? ''
  }`.trim()
}

export const printUnitPrice = (
  variant: Pick<ProductVariant, 'unitPrice' | 'unitPriceMeasurement'>,
) => {
  return variant.unitPrice != null && variant.unitPriceMeasurement != null
    ? `${printMoney(variant.unitPrice)} / ${variant.unitPriceMeasurement.referenceValue}${
        variant.unitPriceMeasurement.referenceUnit?.toLowerCase() ?? ''
      }`.trim()
    : undefined
}

export const getShopifyGID = (
  id: string | number,
  type: 'Product' | 'ProductVariant' | 'Collection',
) => {
  const shopifyGIDStart = 'gid://shopify'

  const isShopifyGID = String(id).startsWith(shopifyGIDStart)

  if (isShopifyGID) {
    return String(id)
  }

  return `${shopifyGIDStart}/${type}/${id}`
}
