'use client'

import Link from 'next/link'
import { useMemo } from 'react'

import Button from '../../components/Button'
import LoadingText from '../../components/LoadingText'
import ProductCard, { ProductForProductCard } from '../../components/ProductCard'
import { useWindowSize } from '../../hooks/useWindowSize'
import { LoadableComponentProps } from '../../types'
import ProductsSlider from './ProductsSlider'

export type FeatureProductsProps = LoadableComponentProps<{
  title: string
  subtitle?: string | null
  link?: {
    title: string
    url: string
  }
  products: Array<ProductForProductCard>
  onAddToBasketClick: (variantId: string) => Promise<void>
  onCardClick: () => void
  accessibility?: {
    price: string
    compareAtPrice: string
  }
}>

export default function FeatureProducts({ data, loading }: FeatureProductsProps) {
  const { windowSize } = useWindowSize()

  const showProductSlider = useMemo(
    () => (windowSize.width > 0 && windowSize.width < 1280) || (data && data.products.length > 3),
    [windowSize.width, data],
  )

  return (
    <div className='mb-16 mt-16 xl:mt-[72px]'>
      <div className='flex flex-col gap-8 xl:gap-10'>
        <div className='flex items-center justify-between px-5 xl:px-10'>
          <h2 className='h2'>{loading ? <LoadingText textLength={15} /> : data.title}</h2>

          {data?.link != null && (
            <Link
              href={data.link.url}
              className='hidden no-underline transition-colors duration-300 hover:text-darkgray lg:block'
            >
              <p className='lg:underline lg:underline-offset-8'>{data.link.title}</p>
            </Link>
          )}
        </div>

        {data?.subtitle && (
          <p className='h6 px-5 xl:px-10'>
            {loading ? <LoadingText className='w-1/3' /> : data.subtitle}
          </p>
        )}

        {showProductSlider ? (
          loading ? (
            <ProductsSlider loading />
          ) : (
            <ProductsSlider
              data={{
                products: data.products,
                onAddToBasketClick: data.onAddToBasketClick,
                onCardClick: data.onCardClick,
                accessibility: data.accessibility,
              }}
            />
          )
        ) : (
          <ul className='flex gap-6 px-5 xl:px-10'>
            {loading
              ? new Array(3).fill(null).map((_, index) => (
                  <li key={`loading-product-card-${index}`} className='grow'>
                    <ProductCard
                      loading
                      imageSizes='(min-width: 768px) 50vw, 100vw'
                      className='aspect-[7/8] min-h-[400px] lg:min-h-[500px] xl:min-h-[439px]'
                    />
                  </li>
                ))
              : data.products.map(product => (
                  <li key={product.variantId} className='grow'>
                    <ProductCard
                      disableDescription
                      imageSizes='(min-width: 768px) 50vw, 100vw'
                      className='aspect-[7/8] min-h-[400px] lg:min-h-[500px] xl:min-h-[439px]'
                      onAddToBasketClick={data.onAddToBasketClick}
                      onCardClick={data.onCardClick}
                      data={{
                        ...product,
                        accessibility: { ...data.accessibility, ...product.accessability },
                      }}
                      loading={loading}
                    />
                  </li>
                ))}
          </ul>
        )}
      </div>

      {data?.link != null && (
        <div className='px-5 pt-12 sm:ml-5 sm:max-w-[335px] sm:px-0 lg:hidden'>
          <Button href={data.link.url} appearance='transparent' className='w-full !max-w-full'>
            {data.link.title}
          </Button>
        </div>
      )}
    </div>
  )
}
