'use client'

import clsx from 'clsx'
import { Image } from 'cms-types'
import { useMemo } from 'react'

import LoadingText from '../../components/LoadingText'
import { useWindowSize } from '../../hooks/useWindowSize'
import { LoadableComponentProps } from '../../types'
import { Testimonial } from './Testimonial'
import { TestimonialSlider } from './TestimonialSlider'

export type TestimonialsProps = LoadableComponentProps<{
  title: string
  testimonials: {
    name: string
    image?: Image
    text: string
    rating: number
  }[]
}>

export default function Testimonials({ loading, data }: TestimonialsProps) {
  const { windowSize } = useWindowSize()

  const showCommunitySlider = useMemo(
    () =>
      windowSize.width >= 640 && data && (data.testimonials.length > 3 || windowSize.width < 1280),
    [windowSize.width, data],
  )

  return (
    <div
      className={clsx(
        'mx-auto w-full px-5 py-10 sm:pr-0 xl:pl-10 xl:pt-20',
        !showCommunitySlider && 'sm:pl-5 sm:pr-5 xl:px-10',
      )}
    >
      <h3 className='h3 pb-8 xl:pb-10'>{loading ? <LoadingText textLength={15} /> : data.title}</h3>

      {showCommunitySlider && !loading ? (
        <TestimonialSlider testimonials={data.testimonials} />
      ) : (
        <ul className='grid grid-cols-4 gap-5 lg:grid-cols-12'>
          {loading
            ? new Array(4)
                .fill(null)
                .map((_, index) => (
                  <Testimonial
                    key={index}
                    className='col-span-4 sm:col-span-2 lg:col-span-4'
                    imageClassName='aspect-[3/4]'
                    loading
                  />
                ))
            : data.testimonials.map((testimonial, index) => (
                <Testimonial
                  key={index}
                  data={testimonial}
                  className='col-span-4 sm:col-span-2 lg:col-span-4'
                  imageClassName='aspect-[3/4]'
                />
              ))}
        </ul>
      )}
    </div>
  )
}
