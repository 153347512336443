import clsx from 'clsx'
import { CSSProperties, forwardRef, useMemo } from 'react'

import LoadingText from '../../components/LoadingText'
import { PayloadImage } from '../../components/PayloadImage'
import ReviewStars from '../../components/ReviewStars'
import { LoadableComponentProps } from '../../types'
import { TestimonialsProps } from '.'

type Props = LoadableComponentProps<
  NonNullable<TestimonialsProps['data']>['testimonials'][number]
> & {
  className?: string
  imageClassName?: string
  reviewContainerStyle?: CSSProperties
  style?: CSSProperties
}

export const Testimonial = forwardRef(function Testimonial(
  { data, loading, className = '', imageClassName = '', reviewContainerStyle, style }: Props,
  reviewContainerRef: React.Ref<HTMLDivElement>,
) {
  const roundedRating = useMemo(() => Math.round((data?.rating ?? 0) * 2) / 2, [data?.rating])

  return (
    <li className={clsx('relative isolate flex w-full overflow-hidden rounded-md', className)}>
      <PayloadImage
        src={data?.image}
        sizes='(min-width: 640px) 50vw, (min-width: 1024px) 33vw, 100vw'
        pictureStyle={style}
        className={clsx('w-full transition-all xl:w-full', imageClassName)}
        objectFit='cover'
        showPlaceholder={loading}
      />

      <div className='absolute bottom-3 left-3 right-3 flex flex-col justify-between gap-3 rounded-sm bg-white p-4 xl:gap-6'>
        <p
          ref={reviewContainerRef}
          className='line-clamp-4 max-h-[4lh] text-ellipsis xl:h-[4lh]'
          style={reviewContainerStyle}
        >
          {loading ? <LoadingText textLength={20} /> : data?.text}
        </p>

        <div className='flex flex-col gap-3 xl:gap-6'>
          <ReviewStars
            ariaLabel={`${data?.rating}`}
            score={loading ? 5 : roundedRating}
            starSize={20}
          />

          <p className='sm'>{loading ? <LoadingText textLength={15} /> : data.name}</p>
        </div>
      </div>
    </li>
  )
})
