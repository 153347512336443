import clsx from 'clsx'
import Link from 'next/link'
import { FC, useMemo } from 'react'

import LoadingText from '../../components/LoadingText'
import { PayloadImage } from '../../components/PayloadImage'
import { LoadableComponentProps } from '../../types'
import { CarouselProps } from './index'

type Props = LoadableComponentProps<NonNullable<CarouselProps['data']>['slides'][number]>

export const CarouselSlide: FC<Props> = ({ loading, data }) => {
  const slideChildren = useMemo(
    () => (
      <>
        {(loading || data.image) && (
          <PayloadImage
            src={data?.image}
            sizes='(min-width: 768px) 372px, 340px'
            skipBlur
            className={clsx('h-16', loading && 'w-52')}
            showPlaceholder={loading}
          />
        )}

        <p className='line-clamp-3 max-w-[340px] flex-none text-ellipsis text-center'>
          {loading ? <LoadingText textLength={20} /> : data.label}
        </p>
      </>
    ),
    [data, loading],
  )

  return (
    <>
      {data?.url ? (
        <Link
          href={data.url}
          className='!flex h-44 max-w-[340px] !grow flex-col items-center justify-center gap-5 px-4 py-10 md:max-w-[372px] lg:h-56'
          target='_blank'
        >
          {slideChildren}
        </Link>
      ) : (
        <div className='!flex h-44 max-w-[340px] !grow flex-col items-center justify-center gap-5 px-4 py-10 md:max-w-[372px] lg:h-56'>
          {slideChildren}
        </div>
      )}
    </>
  )
}
