/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { MouseEvent, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react'

import Icon from '../../components/Icon'
import ReviewStars from '../../components/ReviewStars'
import { FormattedReview } from '../../types'

type ReviewModalProps = {
  onClose: () => void
  showReview: FormattedReview | null
  fromText: string
  verifiedCustomerText: string
}

function ReviewModal({ onClose, showReview, fromText, verifiedCustomerText }: ReviewModalProps) {
  const modalRef = useRef<HTMLDialogElement>(null)
  const [closeAnimationPlaying, setCloseAnimationPlaying] = useState(false)

  useEffect(() => {
    if (showReview !== null && modalRef.current !== null) {
      modalRef.current.showModal()
      modalRef.current.classList.add('openDownToUp')
    }
  }, [showReview])

  const onModalCloseClick = useCallback(() => {
    modalRef?.current?.classList.add('closeDownToUp')
    setCloseAnimationPlaying(true)
  }, [])

  const onEscapeClose = useCallback(
    (e: SyntheticEvent<HTMLDialogElement>) => {
      e.preventDefault()
      onModalCloseClick()
    },
    [onModalCloseClick],
  )

  const onModalClick = useCallback(
    (event: MouseEvent<HTMLDialogElement>) => {
      if (!modalRef.current) return

      // check if we click on backdrop
      const rect = modalRef.current.getBoundingClientRect()

      if (
        event.clientX < rect.left ||
        event.clientX > rect.right ||
        event.clientY < rect.top ||
        event.clientY > rect.bottom
      ) {
        onModalCloseClick()
      }
    },
    [onModalCloseClick],
  )

  const onCloseAnimationEnd = useCallback(() => {
    if (!modalRef.current || !closeAnimationPlaying) return

    modalRef.current.close()
    setCloseAnimationPlaying(false)

    onClose()
  }, [closeAnimationPlaying, onClose])

  return (
    <dialog
      ref={modalRef}
      onAnimationEnd={onCloseAnimationEnd}
      className='fixed m-auto max-w-[90%] rounded-md bg-lightgray shadow-lg backdrop:bg-[#00000080] xl:min-w-[350px] xl:max-w-[50%]'
      onClick={onModalClick}
      onCancel={onEscapeClose}
    >
      <div className='relative flex h-full min-h-[250px] flex-col rounded-md bg-lightgray px-4 py-6 text-left'>
        <div className='flex items-center gap-3'>
          <ReviewStars score={showReview?.rating ?? 0} starSize={13} />

          <span className='flex items-center gap-1 rounded-pill border border-black px-1'>
            <Icon name='MdVerified' />

            <p className='xs'>{verifiedCustomerText}</p>
          </span>

          <button className='right-6 top-6 ml-auto' onClick={onModalCloseClick} autoFocus>
            <Icon name='MdClose' size={20} />
          </button>
        </div>

        <div className='sm mt-6'>{showReview?.text}</div>

        <p className='xs mt-auto pt-4'>
          {showReview?.timeAgo}

          <span>{` ${fromText} ${showReview?.firstName} ${showReview?.lastName?.replace(
            /(?<!^).+/g,
            '.',
          )}`}</span>
        </p>
      </div>
    </dialog>
  )
}

export default ReviewModal
