'use client'

import clsx from 'clsx'
import { useRef } from 'react'
import { useButton } from 'react-aria'

import Icon from '../Icon'

type IconButtonProps = {
  iconName?: string
  render?: (size: number, className: string) => JSX.Element
  onPress: () => void
  /**
   * @default ```normal```
   */
  type?: 'small' | 'normal'
  isDisabled?: boolean
  buttonClassName?: string
  iconClassName?: string
  ariaLabel?: string
  tabIndex?: number
  'data-testid'?: string
}

export default function IconButton({
  iconName,
  render,
  type = 'normal',
  buttonClassName,
  iconClassName,
  ariaLabel,
  tabIndex,
  ...restProps
}: IconButtonProps) {
  const buttonRef = useRef<HTMLButtonElement>(null)

  const { buttonProps } = useButton(restProps, buttonRef)

  return (
    <button
      {...buttonProps}
      className={clsx(
        "relative isolate h-min overflow-hidden text-darkblack before:absolute before:inset-0 before:bg-ecruwhite before:content-[''] after:absolute after:inset-0 after:block  after:origin-bottom-left after:scale-0 after:bg-ecrudark after:opacity-30 after:transition-all after:duration-300 after:content-['']",
        restProps.isDisabled
          ? 'after:scale-0 after:opacity-30'
          : 'hover:after:scale-100 hover:after:opacity-70',
        {
          normal: 'rounded-md p-6',
          small: 'rounded-xs p-3',
        }[type],
        buttonClassName,
      )}
      aria-label={ariaLabel}
      tabIndex={tabIndex}
    >
      {iconName && (
        <Icon
          name={iconName}
          size={type === 'normal' ? 32 : 24}
          className={clsx('relative z-10', restProps.isDisabled && 'opacity-50', iconClassName)}
        />
      )}
      {render &&
        render(
          type === 'normal' ? 32 : 24,
          clsx('relative z-10', restProps.isDisabled && 'opacity-50', iconClassName),
        )}
    </button>
  )
}
