'use client'

import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import './ProductsSliderStyles.css'

import clsx from 'clsx'
import { LazyMotion, m, useInView } from 'framer-motion'
import { ComponentProps, ElementRef, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useId } from 'react-aria'
import Slider, { Settings } from 'react-slick'

import ProductCard, { ProductCardProps } from '../../components/ProductCard'
import { LoadableComponentProps } from '../../types'
import FeatureProducts from '.'

const motionFeatures = () => import('ui/helpers/motionFeatures').then(res => res.default)

type ProductsSliderProps = LoadableComponentProps<{
  products: NonNullable<ComponentProps<typeof FeatureProducts>['data']>['products']
  onAddToBasketClick: (variantId: string) => Promise<void>
  onCardClick: () => void
  accessibility?: NonNullable<ProductCardProps['data']>['accessibility']
}>

export default function ProductsSlider({ data, loading }: ProductsSliderProps) {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [isInitialRender, setIsInitialRender] = useState(true)

  const sliderId = useId()

  const sliderRef = useRef<Slider>(null)

  const sliderSettings: Settings = useMemo(
    () => ({
      swipeToSlide: true,
      speed: 500,
      arrows: false,
      slidesToScroll: 1,
      slidesToShow: 1,
      beforeChange: (_, next) => {
        setCurrentSlide(next)
      },
      infinite: false,
      centerPadding: '26px',
      variableWidth: true,
      className: `product-slider${isInitialRender ? ' initial' : ''}`,
      dots: true,
      // @ts-ignore
      appendDots: dots => <DotsContainer dots={dots} />,
      customPaging: index => (
        <Dot
          isActive={currentSlide === index}
          sliderId={sliderId}
          slideNumber={index + 1}
          onClick={() => {
            sliderRef.current?.slickGoTo(index)
          }}
        />
      ),
      responsive: [
        {
          breakpoint: 1023,
          settings: {
            dots: false,
          },
        },
      ],
    }),
    [currentSlide, isInitialRender, sliderId],
  )

  useLayoutEffect(() => {
    setIsInitialRender(false)
  }, [])

  return (
    <LazyMotion features={motionFeatures} strict>
      {/* @ts-ignore */}
      <Slider
        ref={sliderRef}
        {...sliderSettings}
        className={clsx(
          'product-slider relative isolate !flex flex-col gap-3 lg:gap-8',
          isInitialRender && ' initial',
        )}
      >
        {loading
          ? new Array(3)
              .fill(null)
              .map((_, index) => <ProductSlide key={`loading-product-card-${index}`} loading />)
          : data.products.map((product, index) => (
              <ProductSlide
                key={index}
                data={{
                  product,
                  onAddToBasketClick: data.onAddToBasketClick,
                  onCardClick: data.onCardClick,
                  accessibility: data.accessibility,
                }}
              />
            ))}
      </Slider>
    </LazyMotion>
  )
}

const DotsContainer = ({ dots }: { dots: React.ReactNode }) => {
  return (
    <div className='flex justify-center'>
      <ul className='grid w-[384px] auto-cols-auto grid-flow-col'>{dots}</ul>
    </div>
  )
}

const Dot = ({
  sliderId,
  isActive,
  onClick,
  slideNumber,
}: {
  sliderId: string
  isActive: boolean
  onClick: () => void
  slideNumber: number
}) => {
  return (
    <button
      onClick={onClick}
      aria-label={`Navigate to ${slideNumber} slide`}
      className='w-full py-2'
    >
      <div className='relative h-0.5 bg-ecrudark'>
        {isActive && (
          <m.div
            layoutId={`SliderDot${sliderId}`}
            className='absolute inset-0 z-10 h-0.5 bg-black'
          />
        )}
      </div>
    </button>
  )
}

function ProductSlide({
  data,
  loading,
}: LoadableComponentProps<{
  product: NonNullable<ProductsSliderProps['data']>['products'][number]
  onAddToBasketClick: NonNullable<ProductsSliderProps['data']>['onAddToBasketClick']
  onCardClick: NonNullable<ProductsSliderProps['data']>['onCardClick']
  accessibility?: NonNullable<ProductCardProps['data']>['accessibility']
}>) {
  const slideWrapperRef = useRef<ElementRef<'div'>>(null)
  const isSlideInView = useInView(slideWrapperRef)

  return (
    <div ref={slideWrapperRef}>
      {loading ? (
        <ProductCard
          loading
          imageSizes='(min-width: 768px) 50vw, 100vw'
          className='aspect-[7/8] h-100 lg:h-125 xl:min-h-[500px]'
          disableDescription
        />
      ) : (
        <ProductCard
          imageSizes='(min-width: 768px) 50vw, 100vw'
          className='aspect-[7/8] h-100 lg:h-125 xl:min-h-[500px]'
          disableDescription
          onAddToBasketClick={data.onAddToBasketClick}
          onCardClick={data.onCardClick}
          disableTabIndex={!isSlideInView}
          data={{
            ...data.product,
            accessibility: { ...data.accessibility, ...data.product.accessability },
          }}
        />
      )}
    </div>
  )
}
