'use client'

import { Image } from 'cms-types'

import Button from '../../components/Button'
import HorizontalInfiniteSlider from '../../components/HorizontalInfiniteSlider'
import LoadingText from '../../components/LoadingText'
import { PayloadImage } from '../../components/PayloadImage'
import { LoadableComponentProps } from '../../types'

type CommunityTeaserProps = LoadableComponentProps<{
  title: string
  subTitle: string
  slides: {
    image?: Image
  }[]
  buttonText: string
  url: string
}>

export default function CommunityTeaser({ data, loading }: CommunityTeaserProps) {
  return (
    <div className='bg-lightgray py-10 xl:py-[100px]'>
      <div className='mb-12 flex flex-col items-center xl:mb-20'>
        <h6 className='h6'>{loading ? <LoadingText textLength={15} /> : data.title}</h6>

        <h2 className='h2'>{loading ? <LoadingText textLength={15} /> : data.subTitle}</h2>
      </div>

      <div className='pb-12 xl:pb-20'>
        <HorizontalInfiniteSlider gap={20} pauseOnHover>
          {loading
            ? new Array(20).fill(null).map((_, index) => (
                <div
                  key={`loading-${index}`}
                  className='flex h-[335px] w-[335px] overflow-hidden rounded-sm xl:h-[395px] xl:w-[386px]'
                >
                  <LoadingText className='h-[335px] w-[335px] xl:h-[395px] xl:w-[386px]' />
                </div>
              ))
            : data.slides.map(({ image }, index) => (
                <div
                  key={index}
                  className='flex h-[335px] w-[335px] overflow-hidden rounded-sm xl:h-[395px] xl:w-[386px]'
                >
                  <PayloadImage
                    sizes='(min-width: 1280px) 386px, 335px'
                    src={image}
                    draggable={false}
                    className='h-[335px] w-[335px] xl:h-[395px] xl:w-[386px]'
                    objectFit='cover'
                    imgProps={{
                      draggable: false,
                    }}
                  />
                </div>
              ))}
        </HorizontalInfiniteSlider>
      </div>

      <div className='flex justify-center px-5'>
        <Button
          isLoading={loading}
          href={data?.url}
          appearance='transparent'
          className='w-full no-underline'
        >
          {data?.buttonText}
        </Button>
      </div>
    </div>
  )
}
