'use client'
import './TestimonialSliderStyles.css'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

import { useLayoutEffect, useMemo, useRef, useState } from 'react'
import Slider, { Settings } from 'react-slick'

import { useWindowSize } from '../../hooks/useWindowSize'
import { TestimonialsProps } from '.'
import { Testimonial } from './Testimonial'

export const TestimonialSlider = ({
  testimonials,
}: Pick<NonNullable<TestimonialsProps['data']>, 'testimonials'>) => {
  const [isInitialRender, setIsInitialRender] = useState(true)
  const { windowSize } = useWindowSize()
  const reviewContainersRef = useRef<(HTMLElement | null)[]>([])
  const [reviewContainerHeight, setReviewContainerHeight] = useState(0)

  const testimonialItemSize = useMemo(() => {
    if (windowSize.width < 1280) {
      return {}
    }

    const aspectRatio = 3 / 4
    const minBreakpointWidth = 1280
    const increasedPixelles = Math.max(windowSize.width - minBreakpointWidth, 0)
    const height = Math.max(600, 600 + (225 / 720) * increasedPixelles)
    const width = height * aspectRatio

    return {
      width,
      height,
    }
  }, [windowSize.width])

  const sliderSettings: Settings = useMemo(
    () => ({
      swipeToSlide: true,
      speed: 500,
      arrows: false,
      slidesToScroll: 1,
      slidesToShow: 1,
      dots: false,
      infinite: false,
      variableWidth: true,
      className: `testimonial-slider${isInitialRender ? ' initial' : ''}`,
    }),
    [isInitialRender],
  )

  useLayoutEffect(() => {
    setIsInitialRender(false)

    const updateReviewContainerHeight = () => {
      const heights = reviewContainersRef.current.map(elem => (elem ? elem.scrollHeight : 0))
      const maxHeight = Math.max(...heights, 0)
      setReviewContainerHeight(maxHeight)
    }

    updateReviewContainerHeight()
    window.addEventListener('resize', updateReviewContainerHeight)

    return () => {
      window.removeEventListener('resize', updateReviewContainerHeight)
    }
  }, [])

  return (
    // @ts-ignore
    <Slider {...sliderSettings}>
      {testimonials.map((testimonial, index) => (
        <Testimonial
          ref={el => (reviewContainersRef.current[index] = el)}
          key={index}
          data={testimonial}
          className='sm:w-[335px] lg:w-[440px] xl:w-auto'
          imageClassName='aspect-[3/4] !h-[460px] lg:!h-[600px] xl:!h-auto'
          style={testimonialItemSize}
          reviewContainerStyle={
            reviewContainersRef.current.length > 0 ? { height: reviewContainerHeight } : undefined
          }
        />
      ))}
    </Slider>
  )
}
