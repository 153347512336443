'use client'

import clsx from 'clsx'
import { Image, InfoCardListBlock } from 'cms-types'
import Link from 'next/link'
import { MouseEvent, useMemo, useState } from 'react'

import { PayloadImage } from '../../components/PayloadImage'
import TagList from '../../components/TagList'
import { LoadableComponentProps } from '../../types'
import Icon from '../Icon'
import LoadingText from '../LoadingText'

export type InfoCardType = NonNullable<InfoCardListBlock['cards']>[number]

type Props = LoadableComponentProps<
  Pick<InfoCardType, 'id' | 'title' | 'image'> &
    Pick<InfoCardListBlock, 'toggleHeightLabels'> & {
      description: (JSX.Element | null)[]
      tags: {
        text: string
      }[]
      collapsible?: boolean | null
    }
> & {
  imageSizes: string
}

export function InfoCard({ data, loading, imageSizes }: Props) {
  const [expanded, setExpanded] = useState(false)

  const toggleDescriptionLabel = useMemo(() => {
    if (!data?.toggleHeightLabels) {
      return ''
    }

    const { collapseLinkLabel, expandLinkLabel } = data.toggleHeightLabels
    return data.collapsible && expanded ? collapseLinkLabel : expandLinkLabel
  }, [data?.collapsible, expanded, data?.toggleHeightLabels])

  const toggleExpand = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()

    setExpanded(prev => !prev)
  }

  return (
    <article className='flex flex-col gap-6 xl:gap-10'>
      <div
        className='radius-md relative h-[240px] overflow-hidden xl:h-[280px]'
        style={{ backgroundColor: data?.image?.backgroundColor || 'transparent' }}
      >
        <PayloadImage
          src={data?.image.file as Image}
          sizes={imageSizes}
          objectFit={data?.image?.objectFit ?? 'cover'}
          showPlaceholder={loading}
        />
      </div>

      <div className='flex flex-col gap-5'>
        <p className='h4'>{loading ? <LoadingText textLength={15} /> : data.title}</p>

        <div
          className={clsx('lg [&>div:last-child]:!mb-0', {
            'h-[135px] overflow-hidden xl:h-[150px]': data?.collapsible && !expanded,
          })}
        >
          {loading ? <LoadingText textLength={25} lines={3} /> : data.description}
        </div>
      </div>

      {data && data.tags.length > 0 && (
        <TagList
          listClassName='flex flex-col gap-4'
          selectionMode='none'
          data={{ tags: data.tags, tagsColor: 'lightBrown' }}
        />
      )}

      {data?.collapsible && (
        <div className='flex flex-col gap-4'>
          <Link
            href='#'
            className='w-max border-b-2 border-black no-underline transition-colors duration-300 hover:border-darkgray hover:text-darkgray'
            onClick={toggleExpand}
          >
            <p className='flex items-center gap-2 text-xl'>
              {toggleDescriptionLabel}

              <Icon name='MdEast' size={16} />
            </p>
          </Link>
        </div>
      )}
    </article>
  )
}
