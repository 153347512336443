import { useLayoutEffect, useRef, useState } from 'react'

import Icon from '../../components/Icon'
import ReviewStars from '../../components/ReviewStars'
import { FormattedReview } from '../../types'
import type { ReviewCarouselProps } from './ReviewsCarousel'

type ReviewProps = Pick<ReviewCarouselProps, 'fromText' | 'verifiedCustomerText'> & {
  review: FormattedReview
  focusable?: boolean
  onClick?: () => void
}

export const Review = ({
  review,
  onClick,
  focusable = true,
  fromText,
  verifiedCustomerText,
}: ReviewProps) => {
  const [isTextTruncated, setIsTextTruncated] = useState(false)
  const reviewTextRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    const handleResize = () => {
      if (reviewTextRef.current) {
        setIsTextTruncated(reviewTextRef.current.scrollHeight > reviewTextRef.current.clientHeight)
      }
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [reviewTextRef])

  return (
    <button
      disabled={!isTextTruncated}
      className='flex w-full flex-col rounded-md bg-lightgray px-4 py-6 text-left md:h-[220px] xl:h-[250px]'
      onClick={onClick}
      tabIndex={focusable ? 0 : -1}
    >
      <div className='flex items-center gap-3'>
        <ReviewStars score={review.rating ?? 0} starSize={13} />

        <span className='flex items-center gap-1 rounded-pill border border-black px-1'>
          <Icon name='MdVerified' />
          <p className='xs'>{verifiedCustomerText}</p>
        </span>
      </div>

      <div className='sm mt-6 line-clamp-3' ref={reviewTextRef}>
        {review.text}
      </div>

      <p className='xs mt-3 md:mt-auto'>
        {review.timeAgo}

        <span>{` ${fromText} ${review.firstName} ${review.lastName?.replace(
          /(?<!^).+/g,
          '.',
        )}`}</span>
      </p>
    </button>
  )
}
