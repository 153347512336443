'use client'

import clsx from 'clsx'
import { Image } from 'cms-types'

import LoadingText from '../../components/LoadingText'
import { PayloadImage } from '../../components/PayloadImage'
import { BlogPostCardType, type LoadableComponentProps } from '../../types'

type Size = 'small' | 'big'

type BlogPostCardProps = { size: Size; smallImageSizes: string } & LoadableComponentProps<{
  post: BlogPostCardType
  priority?: boolean
  onCardClick?: () => void
}>

function BlogPostCard({ size, smallImageSizes, data, loading }: BlogPostCardProps) {
  return (
    <button onClick={data?.onCardClick} className='h-full w-full text-left'>
      <div
        className={clsx(
          'group flex h-full flex-col',
          size === 'big' && 'gap-x-10 xl:grid xl:grid-cols-3',
        )}
      >
        <div
          className={clsx(
            'radius-md mb-6 h-fit overflow-hidden',
            {
              small: 'xl:mb-8',
              big: 'xl:col-span-2 xl:mb-0',
            }[size],
          )}
        >
          <PayloadImage
            src={data?.post.preview?.image as Image | undefined}
            sizes={size === 'small' ? smallImageSizes : '(min-width: 1280px) 66vw, 100vw'}
            objectFit='cover'
            priority={data?.priority}
            className={clsx(
              'block w-full transition-all duration-[0.8s] ease-[ease] group-hover:scale-105',
              {
                small: 'aspect-[5/3]',
                big: '!h-[400px] xl:!h-[576px]',
              }[size],
            )}
            showPlaceholder={loading}
          />
        </div>

        <div
          className={clsx('flex flex-col', {
            'justify-center xl:col-start-3 xl:col-end-3': size === 'big',
          })}
        >
          <p className={clsx('h6', size === 'big' ? 'mb-6' : 'mb-2.5')}>
            {loading ? <LoadingText className='w-[30%]' /> : data.post.preview.subtitle}
          </p>

          <h3
            className={clsx(
              'whitespace-break-spaces',
              {
                small: 'h4 mb-4 flex-1 xl:mb-7',
                big: 'h3 mb-5',
              }[size],
            )}
          >
            {loading ? <LoadingText className='w-[60%]' /> : data.post.title}
          </h3>

          {size === 'big' && (
            <p className='mb-6 line-clamp-3 xl:mb-12'>
              {loading ? <LoadingText className='w-[100%]' /> : data.post.preview.description}
            </p>
          )}

          {/* Remove prefetch after blog post pages will be implemented */}
          <a
            data-testid='blog-post-link'
            href={'/post/' + (loading ? '' : data.post.slug)}
            className={clsx(
              'flex-none no-underline transition-colors duration-300 hover:text-darkgray',
              {
                'xl:mt-auto': size === 'small',
              },
              loading && 'pointer-events-none cursor-default',
            )}
          >
            {loading ? (
              <p>
                <LoadingText className='w-[20%]' />
              </p>
            ) : (
              <p className='underline underline-offset-8'>{data.post.preview.linkTitle}</p>
            )}
          </a>
        </div>
      </div>
    </button>
  )
}
export default BlogPostCard
