'use client'

import { Image, Ingredient } from 'cms-types'
import Link from 'next/link'
import { PayloadImage } from 'ui'

export type MappedIngredient = Omit<Ingredient, 'previewImage' | 'description' | 'page'> & {
  previewImage: Image | undefined
  description: (JSX.Element | null)[]
  page: string | undefined
}

type Props = {
  ingredients: MappedIngredient[]
  title?: string | null
  blogPostLinkTitle?: string
  pageTitle?: string
}

export default function HeroIngredients({
  ingredients,
  title,
  pageTitle,
  blogPostLinkTitle,
}: Props) {
  const currentPageTitle = pageTitle ?? title ?? ''

  return (
    <div className='mx-auto max-w-[1440px] py-5 xl:py-10'>
      {currentPageTitle && <h4 className='h4 pb-8 text-center xl:pb-16'>{currentPageTitle}</h4>}

      <ul className='isolate flex flex-col flex-wrap justify-center gap-5 sm:grid-cols-6 md:grid xl:flex xl:flex-row'>
        {ingredients.map(({ id, previewImage, name, subtitle, description, page }) => (
          <li
            key={id}
            className='relative h-[600px] grow overflow-hidden rounded-md md:col-span-3 xl:aspect-[11/15] xl:grow-0'
          >
            <PayloadImage
              sizes='(min-width: 768px) 50vw, (min-width: 1280px) 33vw, 100vw'
              objectFit='cover'
              src={previewImage}
            />

            <div className='absolute bottom-5 left-5 right-5 flex h-[400px] flex-col justify-between overflow-hidden rounded-sm bg-white p-6'>
              <div className='flex max-h-[300px] flex-col gap-7'>
                <div className='flex flex-col gap-3'>
                  <h4 className='h4'>{name}</h4>

                  <h6 className='h6'>{subtitle}</h6>
                </div>

                <div className='line-clamp-3 overflow-hidden'>{description}</div>
              </div>

              {page && (
                <Link
                  href={page}
                  className='no-underline transition-colors duration-300 hover:text-darkgray'
                >
                  <p className='underline underline-offset-8'>{blogPostLinkTitle}</p>
                </Link>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}
