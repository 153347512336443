'use client'

import { Image } from 'cms-types'
import { LazyMotion, m, useScroll, useTransform } from 'framer-motion'
import { useEffect, useMemo, useRef, useState } from 'react'

import Button from '../../components/Button'
import { PayloadImage } from '../../components/PayloadImage'
import { useWindowSize } from '../../hooks/useWindowSize'

const motionFeatures = () => import('ui/helpers/motionFeatures').then(res => res.default)

type SetTeaserProps = {
  image?: Image
  title: string
  description: string
  url?: string
  buttonText: string
  height: {
    mobile: number
    desktop: number
  }
}

export default function SetTeaser({
  image,
  title,
  description,
  url,
  buttonText,
  height,
}: SetTeaserProps) {
  const { isDesktop, isMobile, windowSize } = useWindowSize()
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  const [blockPosition, setBlockPosition] = useState({ top: 0, bottom: 0 })
  const blockRef = useRef<HTMLDivElement>(null)

  const insets = mounted && windowSize.width >= 1280 ? '40px' : '20px'

  const { scrollY } = useScroll()
  const clipPath = useTransform(
    scrollY,
    [blockPosition.top, blockPosition.bottom],
    ['inset(0px 0px 0px 0px round 0px)', `inset(0px ${insets} 0px ${insets} round 12px)`],
  )

  const currentHeight = useMemo(
    () => (mounted ? (isDesktop ? height.desktop : height.mobile) : height.mobile),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mounted, isDesktop, isMobile, height],
  )

  useEffect(() => {
    if (blockRef.current == null) return

    const blockHeight = blockRef.current.offsetHeight

    const windowOffset = windowSize.height / 2
    const blockOffset = blockHeight / 3

    const offsetTop =
      blockRef.current.offsetTop + blockOffset > windowOffset
        ? blockRef.current.offsetTop + blockOffset - windowOffset
        : blockRef.current.offsetTop

    setBlockPosition({ top: offsetTop, bottom: offsetTop + blockHeight })
  }, [windowSize])

  return (
    <LazyMotion features={motionFeatures} strict>
      <m.div
        className='relative left-1/2 flex w-full -translate-x-1/2 transform overflow-hidden lg:mt-6 xl:mt-4'
        ref={blockRef}
        style={{ clipPath }}
      >
        <PayloadImage
          sizes='100vw'
          src={image}
          objectFit='cover'
          className='w-full'
          pictureStyle={{ height: currentHeight }}
        />

        <div className='absolute top-0 flex w-[85%] max-w-[500px] flex-col gap-8 px-10 py-12 sm:max-w-[580px] sm:px-12 xl:max-w-[780px] xl:px-24 xl:py-[100px]'>
          <div className='flex flex-col gap-2 xl:gap-5'>
            <h3 className='h3'>{title}</h3>

            <p>{description}</p>
          </div>

          <Button href={url ?? ''} appearance='transparent'>
            {buttonText}
          </Button>
        </div>
      </m.div>
    </LazyMotion>
  )
}
