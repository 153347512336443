import { useInView } from 'framer-motion'
import { useRef } from 'react'

import Button from '../../components/Button'
import { PayloadImage } from '../../components/PayloadImage'
import { PayloadVideo } from '../../components/PayloadVideo'
import isPayloadImage from '../../helpers/isPayloadImage'
import { Media } from '../../types'

type TeaserSlideProps = {
  title: string
  description: string
  buttonText: string
  media?: Media
  mobileMedia?: Media
  url?: string
  isMediaFullWidth?: boolean
}

export default function TeaserSlide({
  title,
  description,
  buttonText,
  media,
  mobileMedia,
  url,
  isMediaFullWidth,
}: TeaserSlideProps) {
  const ref = useRef<HTMLDivElement>(null)

  const isInView = useInView(ref)

  const mediaHeights = ' !h-[561px] sm:!h-[488px] md:!h-[536px] lg:!h-[576px] xxl!h-[790px]'
  const mediaSplitHeights = ' !h-[283px] sm:!h-[244px] md:!h-[283px] lg:!h-[576px] xxl:!h-[790px]'

  const renderMedia = (className: string) => {
    const getMediaComponent = (media: Media | undefined) =>
      isPayloadImage(media) ? (
        <PayloadImage
          sizes='100vw'
          src={media}
          objectFit='cover'
          className={className}
          tabIndex={isInView ? undefined : -1}
        />
      ) : (
        <PayloadVideo
          autoPlay
          src={media}
          className={`w-full cursor-default object-cover ${className}`}
          tabIndex={isInView ? undefined : -1}
        />
      )

    return (
      <>
        <div className='hidden h-full w-full md:block'>{getMediaComponent(media)}</div>
        <div className='h-full w-full md:hidden'>{getMediaComponent(mobileMedia || media)}</div>
      </>
    )
  }

  if (isMediaFullWidth) {
    return (
      <div ref={ref} className={'radius-lg relative max-h-min overflow-hidden' + mediaHeights}>
        {renderMedia(mediaHeights)}

        <div className='absolute bottom-0 top-0 flex max-w-full flex-col gap-10 px-5 py-12 sm:max-w-[45%] sm:justify-center lg:gap-12 xl:w-full xl:px-14 xxl:max-w-[700px]'>
          <div className='flex flex-col gap-5'>
            <h2 className='h2'>{title}</h2>

            <p>{description}</p>
          </div>

          <Button
            href={url}
            appearance='transparent'
            className='sm:!max-w-max'
            textClassName='sm:!overflow-visible'
            tabIndex={isInView ? undefined : -1}
          >
            {buttonText}
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div
      ref={ref}
      className={
        '!flex flex-col overflow-hidden rounded-lg bg-ecruwhite lg:flex-row-reverse' + mediaHeights
      }
    >
      <div className='flex h-max overflow-hidden lg:flex-1'>
        {renderMedia('lg:flex-1' + mediaSplitHeights)}
      </div>

      <div className='flex items-center lg:flex-1 lg:pr-[0.05px]'>
        <div className='flex w-full flex-col gap-10 px-5 py-6 lg:gap-12 lg:py-10 xl:px-14'>
          <div className='flex flex-col gap-5'>
            <h2 className='h2'>{title}</h2>

            <p>{description}</p>
          </div>

          <Button
            href={url}
            appearance='transparent'
            tabIndex={isInView ? undefined : -1}
            className='w-full !max-w-full sm:!max-w-max'
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </div>
  )
}
