'use client'
import { useScroll } from 'framer-motion'
import { ReactNode, useEffect, useRef, useState } from 'react'

import LoadingText from '../../components/LoadingText'
import { useWindowSize } from '../../hooks/useWindowSize'
import { LoadableComponentProps } from '../../types'

type UniqueSellingPointsProps = LoadableComponentProps<{
  points: {
    title: string
    description: string
  }[]
}>

export default function UniqueSellingPoints({ loading, data }: UniqueSellingPointsProps) {
  const [activeIndex, setActiveIndex] = useState<number>(0)

  const pointsPositionRef = useRef<Array<HTMLLIElement | null>>([])

  const { windowSize } = useWindowSize()

  const { scrollY } = useScroll()

  useEffect(() => {
    const handleScroll = () => {
      const screenMiddlePosition = scrollY.get() + windowSize.height / 2

      let currentIndex = 0

      pointsPositionRef.current.forEach((ref, index) => {
        const position = ref?.offsetTop ?? 0

        if (screenMiddlePosition >= position) {
          currentIndex = index
        }
      })

      if (currentIndex !== activeIndex) {
        setActiveIndex(currentIndex)
      }
    }

    handleScroll()

    document.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [scrollY, activeIndex, windowSize.height])

  return (
    <ul className='flex flex-col items-center px-5 py-10 xl:px-10 xl:py-20'>
      {loading
        ? new Array(3).fill(null).map((_, index) => (
            <li key={`loading-point-${index}`}>
              <SingleSellingPoint
                title={<LoadingText textLength={15} />}
                description={<LoadingText textLength={25} />}
              />
            </li>
          ))
        : data.points.map(({ title, description }, index) => (
            <li
              key={index}
              className={activeIndex === index ? 'text-black' : 'text-gray'}
              ref={ref => {
                pointsPositionRef.current[index] = ref
              }}
            >
              <SingleSellingPoint title={title} description={description} />
            </li>
          ))}
    </ul>
  )
}

const SingleSellingPoint = ({
  title,
  description,
}: {
  title: ReactNode
  description: ReactNode
}) => {
  return (
    <span className='px-5 py-8 transition-colors duration-500 xl:py-10'>
      <h2 className='h2 mb-3 text-center'>{title}</h2>
      <p className='mx-auto text-center lg:max-w-[65%]'>{description}</p>
    </span>
  )
}
