'use client'

import { Image } from 'cms-types'
import { LazyMotion, m, useScroll, useTransform } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'

import Button from '../../components/Button'
import LoadingText from '../../components/LoadingText'
import { PayloadImage } from '../../components/PayloadImage'
import { useWindowSize } from '../../hooks/useWindowSize'
import { LoadableComponentProps } from '../../types'

const motionFeatures = () => import('ui/helpers/motionFeatures').then(res => res.default)

type ShopTeaserProps = LoadableComponentProps<{
  title: string
  buttonText: string
  url: string
  image?: Image
}>

export default function ShopTeaser({ data, loading }: ShopTeaserProps) {
  const blockRef = useRef<HTMLDivElement>(null)
  const [blockPosition, setBlockPosition] = useState({ top: 0, bottom: 0 })
  const [mounted, setMounted] = useState(false)

  const { windowSize } = useWindowSize()
  const { scrollY } = useScroll()
  const isDesktop = windowSize.width >= 1280

  const insets = mounted && isDesktop ? '40px' : '20px'

  const clipPath = useTransform(
    scrollY,
    [blockPosition.top, blockPosition.bottom],
    ['inset(0px 0px 0px 0px round 0px)', `inset(0px ${insets} 0px ${insets} round 12px)`],
  )

  useEffect(() => {
    setMounted(true)
  }, [])

  useEffect(() => {
    if (blockRef.current == null) return

    const blockHeight = blockRef.current.offsetHeight

    const windowOffset = windowSize.height / 2
    const blockOffset = blockHeight / 3

    const offsetTop =
      blockRef.current.offsetTop + blockOffset > windowOffset
        ? blockRef.current.offsetTop + blockOffset - windowOffset
        : blockRef.current.offsetTop

    setBlockPosition({ top: offsetTop, bottom: offsetTop + blockHeight })
  }, [windowSize])

  return (
    <LazyMotion features={motionFeatures} strict>
      <m.div
        className='relative left-1/2 flex w-full -translate-x-1/2 transform overflow-hidden'
        ref={blockRef}
        style={{ clipPath }}
      >
        <PayloadImage
          sizes='100vw'
          src={data?.image}
          objectFit='cover'
          className='!h-[540px] w-full xl:!h-[619px]'
          showPlaceholder={loading}
        />

        <div className='absolute left-1/2 flex w-full -translate-x-1/2 transform justify-start'>
          <div className='flex w-full flex-col items-center gap-8 px-10 py-12 sm:w-auto sm:max-w-[580px] sm:items-start sm:px-12 xl:w-full xl:px-24 xl:py-[100px]'>
            <h3 className='h3 text-center sm:text-start xl:max-w-[421px]'>
              {loading ? <LoadingText textLength={20} /> : data.title}
            </h3>

            <Button
              isLoading={loading}
              href={data?.url ?? '#'}
              className='!w-fit'
              appearance='primary'
            >
              {data?.buttonText}
            </Button>
          </div>
        </div>
      </m.div>
    </LazyMotion>
  )
}
