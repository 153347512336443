'use client'

import clsx from 'clsx'
import { Image } from 'cms-types'
import { CSSProperties, useCallback, useMemo, useState } from 'react'
import { useFocusRing, useHover } from 'react-aria'
import { MdOutlineAdd, MdOutlineShoppingCart } from 'react-icons/md'
import { printMoney } from 'shopify-types/helpers'

import { LoadableComponentProps } from '../../types'
import IconButton from '../IconButton'
import LinkWrapper from '../LinkWrapper'
import LoadingText from '../LoadingText'
import { PayloadImage } from '../PayloadImage'
import Tag from '../Tag'

export type ProductForProductCard = {
  productId: string
  variantId: string
  pageUrl: string
  title: string
  description: string
  image: Image
  hoverImage?: Image
  imageAlt: string
  price: string
  currency: string
  compareAtPrice?: {
    amount: string | number
    currencyCode: string
  }
  isAvailable: boolean
  outOfStockTag: {
    icon?: string
    title: string
  }
  volume?: string
  tag?: {
    icon?: string
    text?: string
    color?: 'accent' | 'white' | 'black' | 'lightBrown' | 'darkBrown'
  }
  accessability?: {
    shoppingButtonLabel: string
  }
}

export type ProductCardProps = {
  style?: CSSProperties
  imageSizes: string
  imagePriority?: boolean
  className?: string
  compact?: boolean
  shoppingButtonSize?: 'small' | 'normal'
  shoppingButtonIconName?: string
  disableTabIndex?: boolean
  disableDescription?: boolean
  onAddToBasketClick?: (variantId: string) => Promise<void>
  onCardClick?: () => void
  disableLink?: boolean
} & LoadableComponentProps<
  ProductForProductCard &
    Omit<ProductForProductCard, 'accessability'> & {
      accessibility?: {
        price?: string
        compareAtPrice?: string
        shoppingButtonLabel?: string
      }
    }
>

export default function ProductCard({
  data,
  loading,
  compact,
  imageSizes,
  imagePriority = false,
  disableTabIndex,
  disableDescription,
  shoppingButtonSize,
  shoppingButtonIconName = 'MdOutlineShoppingCart',
  onAddToBasketClick,
  onCardClick,
  className,
  style,
  disableLink = false,
}: ProductCardProps) {
  const [isLoading, setIsLoading] = useState(false)
  const { isHovered: isImageHover, hoverProps: imageHoverProps } = useHover({})
  const { isFocusVisible: isCardFocusVisible, focusProps } = useFocusRing()

  const itemTag = useMemo(() => {
    if (!data) return null

    const wrappedProps = {
      ...imageHoverProps,
      className: 'absolute left-[10px] top-[10px] z-10',
    }

    const tagClassName = `max-w-full flex-shrink cursor-pointer ${
      compact ? 'origin-top-left scale-75 transform' : ''
    }`

    if (!data.isAvailable) {
      return (
        <div {...wrappedProps}>
          <Tag
            data={{ text: data.outOfStockTag?.title ?? '', color: 'darkBrown' }}
            icon={data.outOfStockTag?.icon}
            rounded={false}
            className={tagClassName}
          />
        </div>
      )
    }

    if (data.tag?.icon || data.tag?.text) {
      return (
        <div {...wrappedProps}>
          <Tag
            data={{ text: data.tag.text ?? '', color: data.tag.color ?? 'accent' }}
            icon={data.tag.icon}
            rounded={false}
            className={tagClassName}
          />
        </div>
      )
    }

    return null
  }, [imageHoverProps, compact, data])

  const handleAddToBasket = useCallback(async () => {
    if (!data) return

    setIsLoading(true)

    await onAddToBasketClick?.(data.variantId)

    setIsLoading(false)
  }, [data, onAddToBasketClick])

  return (
    <LinkWrapper
      href={data ? `${data.pageUrl}?variant=${data.variantId.split('/').at(-1)}` : '#'}
      className='isolate flex flex-col gap-5 no-underline lg:gap-9'
      data-testid={loading ? '' : 'product-card-link'}
      tabIndex={disableTabIndex ? -1 : undefined}
      onClick={onCardClick}
      isDisabled={disableLink || loading}
      {...focusProps}
    >
      <div className='radius-sm relative'>
        {itemTag}

        <div
          {...imageHoverProps}
          className={clsx(
            className,
            'radius-md relative overflow-hidden bg-lightgray lg:rounded-md',
          )}
        >
          <PayloadImage
            src={data?.image}
            alt={data?.image?.alt ?? data?.imageAlt ?? ''}
            objectFit='cover'
            style={style}
            priority={imagePriority}
            sizes={imageSizes}
            className={clsx(
              'mix-blend-darken transition-opacity duration-500',
              (isImageHover || isCardFocusVisible) && data?.hoverImage
                ? 'opacity-0'
                : 'opacity-100',
            )}
            showPlaceholder={loading}
          />

          {data?.hoverImage && (
            <PayloadImage
              src={data?.hoverImage}
              alt={data?.hoverImage.alt ?? data?.imageAlt ?? ''}
              style={style}
              objectFit='cover'
              sizes={imageSizes}
              skipBlur
              className={clsx(
                '!absolute left-0 top-0 mix-blend-darken transition-opacity duration-500',
                isImageHover || isCardFocusVisible ? 'opacity-100' : 'opacity-0',
              )}
              showPlaceholder={loading}
            />
          )}
        </div>

        {data?.isAvailable && (
          <div className='absolute bottom-0 right-0'>
            <div
              className={clsx(
                'relative !rounded-b-none !rounded-tr-none bg-white',
                shoppingButtonSize === 'small' ? 'radius-sm pl-2 pt-2' : 'radius-md pl-4 pt-4',
              )}
            >
              <div className='absolute right-0 top-0 -translate-y-full'>
                <div
                  className='after:radius-pill relative h-2 w-2 bg-white xl:h-2.5 xl:w-2.5'
                  style={{
                    background:
                      'radial-gradient(100% 100% at left 0% top 0%, transparent 99.99%, white)',
                  }}
                />
              </div>

              <div className='absolute bottom-0 left-0 -translate-x-full'>
                <div
                  className='after:radius-pill relative h-2 w-2 bg-white xl:h-2.5 xl:w-2.5'
                  style={{
                    background:
                      'radial-gradient(100% 100% at left 0% top 0%, transparent 99.99%, white)',
                  }}
                />
              </div>

              <IconButton
                data-testid={`product-card-add-${data?.variantId.split('/').at(-1)}`}
                onPress={handleAddToBasket}
                isDisabled={isLoading}
                iconName={
                  shoppingButtonIconName === 'MdOutlineShoppingCart' ||
                  shoppingButtonIconName === 'MdOutlineAdd'
                    ? undefined
                    : shoppingButtonIconName
                }
                ariaLabel={data?.accessibility?.shoppingButtonLabel}
                type={shoppingButtonSize}
                tabIndex={disableTabIndex ? -1 : undefined}
                render={
                  shoppingButtonIconName === 'MdOutlineShoppingCart'
                    ? (size, className) => (
                        <MdOutlineShoppingCart size={size} className={className} />
                      )
                    : shoppingButtonIconName === 'MdOutlineAdd'
                    ? (size, className) => <MdOutlineAdd size={size} className={className} />
                    : undefined
                }
              />
            </div>
          </div>
        )}
      </div>

      <div className='flex flex-col gap-2 transition-opacity hover:opacity-90 lg:gap-3'>
        <p className={compact ? 'md' : 'h6 lg:!text-[28px]'}>
          {loading ? <LoadingText className='w-[50%]' /> : data.title}
        </p>

        {!disableDescription && (
          <p className={`line-clamp-2 ${compact ? 'xs' : 'text-[14px] lg:text-[20px]'}`}>
            {loading ? <LoadingText className='w-[90%]' /> : data.description}
          </p>
        )}
        <p
          className={clsx(
            compact ? 'md' : 'text-[16px] lg:text-[24px]',
            'flex flex-row items-baseline gap-3',
          )}
        >
          {loading ? (
            <LoadingText className='w-[30%]' />
          ) : (
            <>
              <span
                aria-label={`${data.accessibility?.price} ${printMoney({
                  amount: data.price,
                  currencyCode: data.currency,
                })}`}
              >
                {printMoney({
                  amount: data.price,
                  currencyCode: data.currency,
                })}
              </span>
              {data.compareAtPrice && (
                <span
                  className='text-[14px] text-darkgray line-through lg:text-[20px]'
                  aria-label={`${data.accessibility?.compareAtPrice} ${printMoney(
                    data.compareAtPrice,
                  )}`}
                >
                  {printMoney(data.compareAtPrice)}
                </span>
              )}

              {data.volume && ` / ${data.volume}`}
            </>
          )}
        </p>
      </div>
    </LinkWrapper>
  )
}
