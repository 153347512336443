import './ReviewsCarouselStyles.css'

import React, { useMemo, useRef } from 'react'
import Slider, { Settings } from 'react-slick'

import { useWindowSize } from '../../hooks/useWindowSize'
import { FormattedReview, ProductVariant } from '../../types'
import { Review } from './Review'

export type ReviewCarouselProps = {
  selectedVariant: ProductVariant | undefined
  fromText: string
  onReviewClick: (review: FormattedReview) => void
  verifiedCustomerText: string
}

function ReviewsCarousel({
  selectedVariant,
  fromText,
  onReviewClick,
  verifiedCustomerText,
}: ReviewCarouselProps) {
  const sliderRef = useRef<Slider>(null)
  const swiping = useRef(false)
  const { windowSize } = useWindowSize()

  const sliderSettings: Settings = useMemo(
    () => ({
      autoplay: true,
      swipeToSlide: true,
      vertical: false,
      autoplaySpeed: 3000,
      speed: 500,
      arrows: false,
      slidesToScroll: 1,
      slidesToShow: 4,
      responsive: [
        {
          breakpoint: 1023,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 1279,
          settings: {
            slidesToShow: 3,
          },
        },
      ],
      infinite: true,
      pauseOnHover: true,
      beforeChange: () => {
        swiping.current = true
      },
      afterChange: () => {
        swiping.current = false
      },
    }),
    [],
  )

  const showSlider = useMemo(() => windowSize.width >= 768, [windowSize.width])

  return (
    <>
      {showSlider ? (
        // @ts-ignore
        <Slider ref={sliderRef} {...sliderSettings} className='reviews-carousel isolate'>
          {selectedVariant?.reviews.reviews.map((review, index) => (
            <Review
              key={index}
              fromText={fromText}
              review={review}
              verifiedCustomerText={verifiedCustomerText}
              focusable={false}
              onClick={() => !swiping.current && onReviewClick(review)}
            ></Review>
          ))}
        </Slider>
      ) : (
        <div className='flex flex-col gap-5'>
          {selectedVariant?.reviews.reviews
            .slice(0, 5)
            .map((review, index) => (
              <Review
                key={index}
                fromText={fromText}
                review={review}
                verifiedCustomerText={verifiedCustomerText}
                onClick={() => onReviewClick(review)}
              ></Review>
            ))}
        </div>
      )}
    </>
  )
}

export default ReviewsCarousel
