'use client'

import { GlobalTypography, ProductReviewsBlock } from 'cms-types'
import Link from 'next/link'
import { useState } from 'react'

import Icon from '../../components/Icon'
import ReviewStars from '../../components/ReviewStars'
import Star from '../../components/Star'
import { useWindowSize } from '../../hooks/useWindowSize'
import { FormattedReview, ProductVariant } from '../../types'
import ReviewModal from './ReviewModal'
import ReviewsCarousel from './ReviewsCarousel'

type ProductReviewsUIProps = ProductReviewsBlock & {
  selectedVariant: ProductVariant | undefined
  typography: GlobalTypography['productPage']
}

function ProductReviews({ selectedVariant, typography }: ProductReviewsUIProps) {
  const [showModal, setShowModal] = useState<FormattedReview | null>(null)
  const { isDesktop, windowSize } = useWindowSize()
  const { title, fromText, verifiedCustomerText } = typography.reviewsBlock
  const handleReviewClick = (review: FormattedReview) => {
    setShowModal(review)
    document.body.style.overflow = 'hidden'
  }

  const handleModalClose = () => {
    setShowModal(null)
    document.body.style.overflow = 'auto'
  }

  return selectedVariant?.reviews.ratings.reviewsScore != null ? (
    <div className='mx-5 xl:mx-10' id='product-reviews'>
      <div className='mb-12 flex flex-col justify-between gap-8 xl:flex-row xl:items-center xl:gap-0'>
        <h3>{title}</h3>

        <Link
          href={`https://www.reviews.io/product-reviews/store/beyer--shne/${selectedVariant?.sku}`}
          className='flex flex-wrap gap-x-4 gap-y-1 no-underline'
          target='_blank'
        >
          <div className='flex items-center gap-2'>
            {isDesktop ? (
              <Star
                size={20}
                fillCoefficient={selectedVariant?.reviews.ratings.reviewsScore ?? 0 / 5}
              />
            ) : (
              <ReviewStars
                score={selectedVariant?.reviews.ratings.reviewsScore ?? 0}
                starSize={16}
              />
            )}

            <p>{selectedVariant?.reviews?.ratings?.reviewsScore ?? 0} / 5.0</p>
          </div>

          <p className='flex items-center gap-2 border-b border-darkgray text-darkgray'>
            {`${selectedVariant?.reviews?.ratings?.reviewsCount ?? 0} ${typography.reviewsText}`}
            <Icon name='MdLaunch' size={windowSize.width < 1024 ? 16 : 20} />
          </p>
        </Link>
      </div>

      <div>
        <ReviewsCarousel
          selectedVariant={selectedVariant}
          fromText={fromText}
          onReviewClick={handleReviewClick}
          verifiedCustomerText={verifiedCustomerText}
        />
      </div>

      {showModal !== null && (
        <ReviewModal
          onClose={handleModalClose}
          showReview={showModal}
          fromText={fromText}
          verifiedCustomerText={verifiedCustomerText}
        />
      )}
    </div>
  ) : null
}

export default ProductReviews
