import { useId as useReactId, useMemo } from 'react'

export const useId = () => {
  const id = useReactId()

  const cssEscaped = useMemo(() => id.replace(/:/g, '\\:'), [id])
  const jsEscaped = useMemo(() => id.replace(/:/g, '$'), [id])

  return { id, cssId: cssEscaped, jsId: jsEscaped }
}
